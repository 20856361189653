.vertical-nav {
  min-width: 16rem;
  width: 16rem;
  height: 100vh;
  position: fixed;
  top: 56px; /* izmjereno da sidebar i navbar se ne preklapaju*/
  left: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}

.page-content {
  width: calc(100% - 16rem);
  margin-left: 16rem;
  transition: all 0.4s;
  padding-right: 0;
  overflow: hidden;
  min-height: 100vh;
}

/* for toggle behavior */

#sidebar.active {
  margin-left: -16rem;
}

#content.active {
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -16rem;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
    margin: 0;
  }

  #content.active {
    margin-left: 16rem;
    width: calc(100% - 16rem);
  }

  #content.active * {
    overflow: hidden; /* kad je togglan sidebar da se ne clipa text */
    white-space: nowrap;
  }
}

body {
  background: #17a2b8;
  background: -webkit-linear-gradient(to right, #17a2b8, #c2e59c);
  background: linear-gradient(to right, #17a2b8, #c2e59c);
  min-height: 100vh;
  overflow-x: hidden;
}
.navbar {
  background: #17a2b8;
  background: -webkit-linear-gradient(to right, #17a2b8, #c2e59c);
  background: linear-gradient(to right, #17a2b8, #c2e59c);
}

.separator {
  margin: 3rem 0;
  border-bottom: 1px dashed #fff;
}

.text-uppercase {
  letter-spacing: 0.1em;
}

.text-gray {
  color: #aaa;
}
